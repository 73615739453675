<template>
  <onboarding-layout>
    <div class="login-container">
      <h1 class="h2 mb-3">
        Wachtwoord instellen
      </h1>

      <form class="form-block" @submit.prevent="changePassword">
        <Errors :errors="errors" />

        <div class="form-group mb-2">
          <label class="visually-hidden" for="password">
            Wachtwoord
          </label>

          <input
            class="form-control form-control--center-placeholder"
            type="password"
            id="password"
            name="password"
            v-model="formInput.password"
            placeholder="Wachtwoord"
          />
        </div>

        <div class="form-group mb-2">
          <label class="visually-hidden" for="password_confirmation">
            Wachtwoord bevestigen
          </label>

          <input
            class="form-control form-control--center-placeholder"
            type="password"
            id="password_confirmation"
            name="password_confirmation"
            v-model="formInput.password_confirmation"
            placeholder="Wachtwoord bevestigen"
          />
        </div>

        <div class="d-flex">
          <Button
            button-type="submit"
            :disabled="resetPasswordLoadingState === 'loading'"
          >
            {{ resetPasswordLoadingState === 'loading'
              ? 'Een moment geduld... '
              : 'Wachtwoord aanpassen '
            }}
          </Button>
        </div>
      </form>
    </div>
  </onboarding-layout>
</template>

<script>
import Errors from '../../components/Errors.vue';
import mostUsedPasswords from '../../helpers/mostUsedPasswords';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';
import OnboardingLayout from '../Onboarding/OnboardingLayout.vue';
import Button from '../../components/Button.vue';

const passwordResetCredentials = {
  email: '',
  password: '',
  password_confirmation: '',
  token: '',
};

export default {
  name: 'PasswordReset',

  components: {
    Button,
    OnboardingLayout,
    Errors,
  },

  data() {
    return {
      formInput: passwordResetCredentials,
      errors: [],
    };
  },

  mounted() {
    const { query, params } = this.$route;

    if (
      !query.email
      || typeof query.email !== 'string'
      || typeof params.token !== 'string'
    ) {
      this.$router.push({ name: '404' });
      return;
    }

    this.formInput.email = query.email;
    this.formInput.token = params.token;
  },

  methods: {
    changePassword() {
      this.errors = [];

      if (!this.validate()) {
        return;
      }

      this.$store.dispatch('resetPassword', this.formInput)
        .then(this.$store.dispatch('fetchUser'))
        .then(() => {
          this.$store.commit('setFlashMessage', [
            'We hebben je wachtwoord gewijzigd.',
          ]);

          this.$router.push({ name: 'Feed' });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.errors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.errors = retrieveErrorsFromResponse(err.response);
        });
    },

    validate() {
      if (!this.formInput.password) {
        this.errors.push('Voer een wachtwoord in');
      }

      if (!this.formInput.password_confirmation) {
        this.errors.push('Voer beide wachtwoord velden in');
      }

      if (this.formInput.password_confirmation !== this.formInput.password) {
        this.errors.push('Beide wachtwoorden moeten overeen komen');
      }

      if (mostUsedPasswords.indexOf(this.formInput.password) >= 0) {
        this.errors.push('Dit wachtwoord is onveilig, gebruik een ander wachtwoord');
      }

      return this.errors.length === 0;
    },
  },

  computed: {
    resetPasswordLoadingState() {
      return this.$store.getters.resetPasswordLoadingState;
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 25rem;
  margin: auto;
}
</style>
