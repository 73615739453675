const passwords = [
  '123456',
  '123456789',
  'qwerty',
  'password',
  '111111',
  '12345678',
  'abc123',
  '1234567',
  'iloveyou',
  '123123',
  '555555',
  '666666',
  '654321',
  'test123',
  'test1234',
  'test12345',
  'pass123',
];

export default passwords;
